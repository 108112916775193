@font-face {
  font-family: 'Goldman';
  src: url(./assets/fonts/Goldman-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NotoSans';
  src: url(./assets/fonts/NotoSans-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Goldman';
  src: url(./assets/fonts/Goldman-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Play';
  src: url(./assets/fonts/Play-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
